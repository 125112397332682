/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-15 16:43:55
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-13 16:34:07
 */
export const columns = [
  {
    title: '大区',
    dataIndex: 'areaName',
    key: 'areaName',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },{
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },{
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
  title: '累计折扣金额',
  dataIndex: 'rebateTotal',
  key: 'rebateTotal',
  /*width:150,*/
  align:'center',
  ellipsis:true,
  scopedSlots: { customRender: 'rebateTotal'}
},
{
  title: '累计使用金额',
  dataIndex: 'usedRebateTotal',
  key: 'usedRebateTotal',
  /*width:150,*/
  align:'center',
  ellipsis:true,
  scopedSlots: { customRender: 'usedRebateTotal' }
},
{
  title: '当前可用折扣金额',
  dataIndex: 'canUsedRebate',
  key: 'canUsedRebate',
  // width:150,
  align:'center',
  ellipsis:true,
  scopedSlots: { customRender: 'canUsedRebate' }
}
]
